import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { PromptService } from '../../services/prompt.service';

@Injectable({ providedIn: 'root' })
export class PlaygroundResolver implements Resolve<any> {
  constructor(private promptService: PromptService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.promptService.getPageData(route.paramMap.get('id')).pipe(
      catchError((error) => {
        console.log('THERE WAS A ERROR GETTING DATA');
        return throwError(error);
      }));
  }
}
