import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { QueryService } from '../../services/query.service';
import { FormBuilder, Validators } from '@angular/forms';
import { IChatHistory, IQuery } from '../../shared/interfaces/query.interface';
import { faCopy, faHashtag, faTag } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { PromptService } from '../../services/prompt.service';

@Component({
    selector: 'app-playground',
    templateUrl: './playground.component.html',
    styleUrls: ['./playground.component.scss']
})
export class PlaygroundComponent implements OnInit, OnDestroy {
    data: any;
    faTag = faTag;
    faHashtag = faHashtag;
    faCopy = faCopy;
    stage: number | null = 1;
    query: Observable<any | null> = this.queryService.latestQuery$;
    loading: Observable<boolean> = this.queryService.loading$;
    latestId!: string;
    token!: string;
    submitted = false;
    messageForm = this.fb.group({
        message: [null, [Validators.required]],
    });
    private pollSubscription: Subscription = new Subscription;
    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private queryService: QueryService,
        private promptService: PromptService,
        public fb: FormBuilder,
        private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.data = this.route.snapshot.data['data'];
        // console.log({parentData: this.data})
        this.query.subscribe((data: IQuery) => {
            this.latestId = data?._id;
            this.token = data?.token;
            if (data && data.stage && data.stage >= 3) {
                this.stage = data.stage;
            }
            if (data && data.stage && data.chat_history.length > 0) {
                this.stage = data.stage;
            }
        });
    }
    ngOnDestroy() {
        if (this.pollSubscription) {
            this.pollSubscription.unsubscribe();
        }
    }
    stageChange(stage: number | null) {
        this.stage = stage;
        if (stage === 2) {
            this.queryService.checkStatus(true);
        }
    }
    public submitForm(values: any): void {
        this.submitted = true;
        if (this.messageForm.invalid) {
            return;
        }
        const query: IChatHistory = {
            query: values.message,
            content: values.message,
            type: 'text',
            role: "user",
        }
        this.queryService.appendMessageLocal(query);
        // this.queryService.setLoading(true);
        console.log({ stage: this.stage })
        switch (this.stage) {
            case 3:
                this.queryService.yourName(this.latestId, query).subscribe(
                    response => {
                        console.log(response)
                        this.queryService.checkStatus(true);
                        this.messageForm.reset();
                    }
                )
                break;
            case 4:
                this.queryService.friendName(this.latestId, query).subscribe(
                    response => {
                        console.log(response)
                        this.queryService.checkStatus(true);
                        this.messageForm.reset();
                    }
                )
                break;
            case 5:
                this.queryService.ownGoal(this.latestId, query).subscribe(
                    response => {
                        console.log(response)
                        this.queryService.checkStatus(true);
                        this.messageForm.reset();
                    }
                )
                break;
            default: this.queryService.yourName(this.latestId, query).subscribe(
                response => {
                    console.log(response)
                    this.queryService.checkStatus(true);
                    this.messageForm.reset();
                }
            )
                break;
        }
    }
    public copyToClipboard(text: string): void {
        // this.toastService.newToast({message:'Copied to clipboard', type:'success'});
        this.toastr.success('Copied', 'Copying to clipboard');
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text);
        } else {
            // Clipboard API not supported, fallback to alternative method
            // Implement your own logic here
        }
    }
    saveQuery(event: { _id: string, query: string, change: string; }): void {
        this.promptService.updateHistory(event._id, event.query, event.change).subscribe(response => {
            this.toastr.success('Saved', 'updated successfully');
        }, error => {
            this.toastr.error('Error', 'unable to save');
        });
    }

}