
<div class="playground-header">
    <div class="header">
        <h2>{{data.title}}</h2>
        <span><button class="btn">Save</button></span>
    </div>
    <div class="sub-header">
        <span class="version">Version {{data.iteration}}</span>
        <span class="token" (click)="copyToClipboard(data.token)">
            <p>{{data.token}}</p>
            <fa-icon  [icon]="faCopy"></fa-icon>
        </span>
    </div>
</div>
<div class="playground-container">
    <div class="empty-panel">
        <ng-container *ngFor="let system of data.system_prompt_includes">
            <app-comp-prompt [promptId]="system._id" (save)="saveQuery($event)"></app-comp-prompt>
        </ng-container>
    </div>
    <div class="empty-panel">
        <ng-container *ngFor="let system of data.prompt_includes">
            <app-comp-prompt [promptId]="system._id" (save)="saveQuery($event)">
                <div class="span">
                    <span class="badge text-bg-info">[OWNGOAL] = Text Input</span>
                    <span class="badge text-bg-info">[USER_1] = User Name</span>
                    <span class="badge text-bg-info">[USER_2] = Friend Name</span>
                </div>
            </app-comp-prompt>
        </ng-container>
    </div>
    <div class="empty-panel">
        <div class="app-panel">
            <h2>Application Simulation</h2><br>
            <div class="simulation">
                <!-- <img src="/assets/images/phone_035.png" alt="Simulation"> -->
                <div class="simulation-content" [attr.data-stage]="stage">
                    <div class="simulation-container">
                        <div class="simulation-contents">
                                <ng-container *ngIf="stage === 1">
                                    <img src="/assets/images/snicker_logo.png" alt="snickers"
                                        style="width: 80%;margin: 0 auto;display: inline-block; margin-top: 40px;" />
                                    <div class="action-block">
                                        <button class="app-cta" (click)="stageChange(2)">Generate</button>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="stage !== 1">
                                    <div class="convo-container">
                                        <div class="chat-header">
                                            <img (click)="stageChange(1)" src="/assets/images/back.png" alt="back"
                                                style="width: 19px; display: inline-flex;" />
                                            <img src="/assets/images/snicker_logo.png" alt="snickers"
                                                style="width: 60%;display: inline-flex;" />
                                        </div>
                                        <div class="chat-block">
                                            <div class="chat-wrapper">
                                                <div class="chat-container">
                                                    <ng-container *ngIf="(query | async); let query">
                                                        <div *ngFor="let message of query.chat_history"
                                                            [ngClass]="message.role!=='user'?'message-received':'message'">
                                                            {{message.content}}</div>
                                                    </ng-container>
                                                    <div class="message-received loading" *ngIf="(loading | async); let loading">
                                                        <div class="loader">
                                                            <span class="bubble bubble-one"></span>
                                                            <span class="bubble bubble-two"></span>
                                                            <span class="bubble bubble-three"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-area">
                                                <form [formGroup]="messageForm">
                                                    <input type="text" placeholder="Type your name" class="query"
                                                        formControlName="message" />
                                                    <button class="naked"><img src="/assets/images/send.png" alt="send"
                                                            (click)="submitForm(messageForm.getRawValue())"></button>
                                                    <span class="text-danger form-error"
                                                        *ngIf="submitted && messageForm.get('message')?.dirty && messageForm.get('message')?.errors">Invalid
                                                        message</span>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                        </div>
                    </div>
                    <div class="overlay"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="empty-panel">
        <div class="app-panel">
            <h2>Response</h2>
            <div class="mb-3">
                <label for="formFile" class="form-label">Default file input example</label>
            </div>
        </div>
        <div class="app-panel">
            <h2>Audio Response</h2>
            <div class="mb-3">
                <label for="formFile" class="form-label">Default file input example</label>
            </div>
        </div>
        <div class="app-panel">
            <h2>Video Response</h2>
            <div class="mb-3">
                <label for="formFile" class="form-label">Default file input example</label>
            </div>
        </div>
    </div>
</div>