import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModule } from 'ngx-bootstrap/alert';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlaygroundComponent } from './playground.component';
import { PlaygroundRoutingModule } from './playground-routing.module';
import { PlaygroundResolver } from './playground.resolver';
import { Injectable } from '@angular/core';
import { QueryService } from '../../services/query.service';
import { ChatService } from '../../services/chat.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PromptBaseComponent } from './components/prompt/prompt.base.component';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../../shared/shared.module';



@NgModule({
  declarations: [
    PlaygroundComponent
  ],
  providers: [PlaygroundResolver, QueryService, ChatService],
  imports: [
    CommonModule,
    ToastrModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    PlaygroundRoutingModule,
    SharedModule // Import SharedModule from the correct file path
  ],
  exports: [
    PlaygroundComponent
  ]
})
export class PlaygroundModule { }
